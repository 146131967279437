<template>
    <ui-data-table 
        :p-headers="headers"
        p-title="Fornecedores"
        p-read-link="providers.read.one"
        p-create-link="providers.create"
        p-name="peoples"
    />
</template>
<script>

import {uiDataTable} from '@/components/ui'
export default {
    name: 'vwCompanyRead',
    components: {
        uiDataTable  
    },
    data(){
        return {
            headers: [{
                text: 'id',
                value: 'id'
            }, {
                text: 'Nome',
                value: 'nome'
            }, {
                text: 'Razão Social',
                value: 'razaosocial'
            }, {
                text: 'Cnpj',
                value: 'cnpj'
            }, {
                text: 'Email',
                value: 'email'
            }, {
                text: 'Telefone',
                value: 'telefone'
            }, {
                text: 'Inscrição',
                value: 'inscricao'
            }, {
                text: 'Cadastrado',
                value: 'criado_em'
            }, {
                text: 'Ações', 
                value: 'actions', 
                sortable: false
            }],
        }
    }
}
</script>